<template>
   <ObiText v-bind="attrs" v-on="$listeners">
      <CheckedIcon v-if="checked" />
      <UncheckedIcon v-else />
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';
import CheckedIcon from '@/components/Icons/portals/CheckedIcon';
import UncheckedIcon from '@/components/Icons/portals/UncheckedIcon';

export default {
   name: 'ObiCheckbox',
   components: {
      CheckedIcon,
      UncheckedIcon,
   },
   props: {
      checked: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>

<style lang="scss" scoped></style>
